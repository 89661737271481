.header {
    position: fixed;
    width: 100%;
    height: auto;
    z-index: 9999;
    top: 0;
    left: 0;

    .container {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            height: 81px;
            width: 102px;
        }

        .links {
            display: flex;
            align-items: center;
            gap: 50px;

            .link {
                color: #fff;
                font-family: "Space Grotesk";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 31px;
                text-transform: uppercase;
                position: relative;
                display: inline-flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                span {
                    visibility: hidden;
                    scale: 0;
                    transition: all 0.3s;
                    transform-origin: top;
                    width: 150px;
                    background-color: #000;
                    color: #fff;
                    font-size: 14px;
                    text-align: center;
                    padding: 10px;
                    border-radius: 6px;
                    top: 100%;
                    left: 50%;
                    margin-left: -75px;
                    position: absolute;
                    z-index: 2;

                    // &::after {
                    //     position: absolute;
                    //     content: "";
                    //     height: 10px;
                    //     top: -5px;
                    //     left: 50%;
                    //     width: 10px;
                    //     rotate: 45deg;
                    //     background-color: rgba(0, 0, 0, 0.8);
                    //     z-index: -1;
                    // }

                    a {
                        color: #fff;
                        display: block;
                        text-decoration: none;

                        &:hover {
                            opacity: 0.7;
                        }
                    }

                    // &:hover {
                    //  background-color: #000;
                    // }
                }

                &:hover {
                    opacity: 0.7;

                    span {
                        visibility: visible;
                        scale: 1;
                    }
                }
            }

            .sign_up {
                all: unset;
                cursor: pointer;
                font-family: "Space Grotesk";
                font-style: normal;
                padding: 10px 20px;
                color: #fff;
                border: 1px solid #ffffff;
                border-radius: 8px;
                font-weight: 300;
                font-size: 24px;
                line-height: 31px;
            }
        }
    }
}

.header2 {
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 9999;
    width: 100%;

    .container {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        gap: 50px;
        align-items: center;

        img {
            height: 40px;

            &:first-child {
                height: 30px;
                cursor: pointer;
            }
        }
    }
}

.hero_section {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        height: 100vh;
        width: 100vw;
        object-fit: cover;

        &.space {
            object-position: center;
            // object-fit: contain;
            object-position: 40% 40%;
        }

        &.spaceman {
            // display: none;
            right: 26%;
            bottom: 12%;
            height: 30vh;
            width: 35vh;
        }

        &.road {
            // display: none;
            // object-fit: cover;
            object-position: bottom;
            top: 0;
            right: 0;
            height: 100vh;
        }
    }

    .gradient {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: radial-gradient(110.28% 110.28% at 16.51% 56.44%,
            rgba(16, 20, 38, 0.8) 0%,
            rgba(0, 0, 0, 0) 100%);
        mix-blend-mode: normal;
    }

    .text_section {
        z-index: 1;
        position: absolute;
        height: 100%;
        width: 100%;

        .container {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-self: center;
            flex-direction: column;
            color: #fff;

            h1 {
                margin-top: 100px;
                font-size: 4.375rem;
                line-height: 4.775rem;
                font-family: "Anybody";
                font-style: italic;
                font-weight: 900;
                color: #e6dfd7;
            }

            p {
                font-family: "Space Grotesk";
                font-style: normal;
                max-width: 630px;
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 2.563rem;
                color: rgba(255, 255, 255, 0.95);
            }
        }
    }
}

.partnerModal {
    .modal-body {
        background: #231222;

        .container {
            top: 0;
            left: 50%;
            height: 100%;
            padding-top: 120px;
            transform: translate(-50%);
            position: absolute;
            display: flex;
            gap: 20px;
            flex-direction: column;
            // justify-content: center;

            h1 {
                color: #e6dfd7;
                font-family: "NeueMetanaNextSemiBold";
                font-style: normal;
                font-weight: 600;
                font-size: 42px;
                line-height: 52px;
            }

            .partner_card {
                display: flex;
                gap: 20px;
                flex-direction: column;
                justify-content: center;

                img {
                    height: 100px;
                    object-fit: contain;
                    object-position: left;
                    width: 100%;
                }

                .title {
                    margin-top: 10px;
                    font-family: "Anybody";
                    font-style: italic;
                    font-weight: 700;
                    font-size: 42px;
                    line-height: 50px;
                    color: #e6dfd7;
                }

                .desc {
                    font-family: "Space Grotesk";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 26px;
                    color: #fff;
                }
            }
        }

        .gradient {
            top: 0;
            left: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            background: radial-gradient(110.28% 110.28% at 16.51% 56.44%,
                rgba(16, 20, 38, 0.8) 0%,
                rgba(0, 0, 0, 0) 100%);
            mix-blend-mode: normal;
        }
    }
}

.web_menu_model {
    width: 100vw !important;
    padding-top: 130px;
    background: rgb(36, 18, 34, 1) !important;

    a,
    span {
        display: block;
        font-family: "Space Grotesk";
        pointer-events: none;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        text-transform: uppercase;
        text-decoration: none;
        color: #e6dfd7;
        margin-bottom: 30px;
    }

    span {
        pointer-events: all;
        cursor: pointer;
    }
}

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 830px) and (max-height: 1200px) and (orientation: portrait) {
    .hero_section {
        img {
            &.spaceman {
                right: -10%;
                bottom: 12%;
                height: 30vh;
                width: 35vh;
            }
        }
    }
}

@media only screen and (max-width: 1200px) and (max-height: 830px) and (orientation: landscape) {
    .hero_section {
        img {
            &.spaceman {
                right: 20%;
                bottom: 10%;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .partnerModal {
        text-align: center;

        .modal-body {
            .container {
                display: block;
                padding-top: 100px;

                h1 {
                    font-size: 26px;
                    line-height: 30px;
                    margin-bottom: 20px;
                }

                .partner_card {

                    .title,
                    .desc {
                        display: none;
                    }

                    img {
                        width: 70%;
                        margin: auto;
                        object-position: center;
                    }
                }
            }
        }
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(36, 18, 34, 1);
    z-index: 999999;
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    img {
        animation: spin 6s infinite;
        -webkit-animation: spin 6 infinite;
        -moz-animation: spin 6 infinite;
        -o-animation: spin 6 infinite;
        -ms-animation: spin 6 infinite;

        &:last-child {
            all: unset;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // animation: tremble 2s infinite;
        }
    }

    h2 {
        font-family: "Anybody";
        position: relative;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 31px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); // &:before {
        //     content: attr(data-text);
        //     position: absolute;
        //     overflow: hidden;
        //     max-width: 7em;
        //     white-space: nowrap;
        //     color: #fff;
        //     animation: loading 4s infinite;
        // }
    }

    // .box {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   gap: 15px;
    // }

    // .loader_box_first,
    // .loader_box_second,
    // .loader_box_third,
    // .loader_box_fourth,
    // .loader_box_five {
    //   height: 30px;
    //   background: url("/assets/loader/2.svg");
    //   background-repeat: no-repeat !important;
    //   background-size: 100% 100% !important;
    //   width: 30px;
    //   animation: wave 2s linear infinite;
    //   // filter: invert(100%);
    //   // -webkit-filter: invert(100%);
    // }

    // .loader_box_first {
    //   background: url("/assets/loader/1.svg");
    // }
}

// @keyframes wave {
//     to {
//         background: url("/assets/loader/1.svg");
//     }
// }

// @keyframes loading {
//     0% {
//         max-width: 7em;
//     }
//     50% {
//         max-width: 0;
//     }

//     100% {
//       max-width: 7em;
//     }
// }

@keyframes tremble {
    0% {
        scale: 1;
    }

    50% {
        scale: 1.1;
    }

    100% {
        scale: 1;
    }
}

@keyframes spin {
    0% {
        rotate: 0deg;
    }

    100% {
        rotate: 360deg;
    }
}