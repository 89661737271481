.swiper-gl {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  .swiper-slide,
  swiper-slide {
    position: relative;
  }
  .swiper-gl-image {
    display: none;
  }
  > canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.swiper-gl-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  top: 0;
}
