@font-face {
	font-family: "NeueMetanaNextSemiBold";
	src: local("NeueMetanaNext-SemiBold"), url(./assets/fonts/NeueMetanaNext-SemiBold.ttf) format("truetype");
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Anybody", cursive;
	/* font-family: 'Space Grotesk', sans-serif; */
	overflow: hidden;
/*	max-height: 100vh;*/
}

@media screen and (max-width: 768px) {
	body {
		all: unset;
	}
}
