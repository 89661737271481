footer {
    // margin-top: 200px;
    padding: 50px 0;
    background: #e6dfd7;
    // min-height: 300px;

    .footer_row {
        align-items: center;

        a {
            text-decoration: none;
            color: #241222;
            font-family: "Space Grotesk";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            display: inline-block;
            width: max-content;
            pointer-events: none;

            &:hover {
                color: #241222;
                opacity: 0.6;
            }
        }

        .footer_left_section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            img {
                height: 50px;
            }

            .terms_and_conditions {
                display: flex;
                gap: 15px;
                // margin-top: 50px;
                // align-items: center;
                justify-content: center;

                span {
                    width: 1px;
                    background-color: #000000;
                }

                a {
                    font-family: "Space Grotesk";
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    // line-height: 22px;
                    text-transform: uppercase;
                    color: #000000;
                }
            }
        }

        .footer_mid_section {
            display: flex;
            gap: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .footer_right_section {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;

            p {
                font-family: "Space Grotesk";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                text-transform: uppercase;
            }

            .icons_section {
                margin-top: -10px;
                display: flex;
                gap: 20px;

                a {
                    pointer-events: all;
                }

                img {
                    height: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    footer {
        padding: 20px;

        a {
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            display: block;
            margin: auto;
        }

        .footer_left_section {
            align-items: center;

            img {
                height: 60px;
            }
        }

        .footer_mid_section,
        .footer_right_section {
            margin-top: 20px;
            gap: 15px;
        }

        .footer_right_section {
            justify-content: center;
            align-items: center;
        }
    }
}