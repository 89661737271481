.mobile_header_1 {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    // padding: 20px;
    height: 64px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    &.scrolled {
        backdrop-filter: blur(12px);
    }

    img {
        height: 25px;

        &.main_logo {
            height: 30px;
        }
    }

    .menuIcon {
        position: absolute;
        top: 22px;
        left: 25px;
    }
}

.menu_off_canvas {
    width: 100vw !important;

    .offcanvas-body {
        padding: 100px 10px 10px 50px;
        background: rgb(36, 18, 34, 1);
        color: #fff;
        display: flex;
        flex-direction: column;
        gap: 30px;

        a,
        span {
            display: inline-block;
            font-family: "Space Grotesk";
            pointer-events: none;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            text-transform: uppercase;
            text-decoration: none;
            color: #e6dfd7;
        }

        span {
            pointer-events: all;
        }
    }
}

.mobile_hero_section {
    position: relative;
    height: 100%;
    width: 100vw;
    overflow: hidden;

    img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;

        // &.mainbackground {
        // }

        &.top_image {
            left: -13%;
        }

        &.road_img {
            left: -12%;
            // scale: 1.5;
            // height: 60%;
            // object-fit: contain;
            bottom: 0;
        }

        &.ship_image {
            bottom: 5%;
        }
    }

    // .bottom_image,
    // .ship_image,
    // .bg_image {
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     object-fit: cover;

    //     &.top_image {
    //         height: 60%;
    //         object-fit: contain;
    //     }
    // }

    .text_section {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        padding: 20px;
        display: flex;
        z-index: 1;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        background: rgb(0, 0, 0, .4);

        h1 {
            font-family: "Anybody";
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 40px;
            color: #e6dfd7;
        }

        p {
            font-family: "Space Grotesk";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.95);
        }
    }
}

.m_slider {
    .mobile_slide_card {
        background: rgb(36, 18, 34, 1);

        img {
            height: 250px;
            width: 100%;
            object-fit: cover;
            position: sticky;
            top: 60px;
            // perspective: 10px;
        }

        .mobile_slide_card_body {
            padding: 40px 30px;
            // background: rgb(36, 18, 34, 1);
            background: rgb(0, 0, 0, .2);
            z-index: 1;
            position: sticky;
            // text-align: center;
            // transform-style: preserve-3d;
            top: 60px;

            .title {
                font-family: "Anybody";
                font-style: italic;
                font-weight: 700;
                font-size: 23px;
                line-height: 27px;
                color: #e6dfd7;
            }

            .desc {
                margin-top: 10px;
                font-family: "Space Grotesk";
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 21px;
                color: #ffffff;
            }
        }
    }
}