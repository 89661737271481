.swiper {
    width: 100%;
    height: 100vh;
    position: relative;
    margin: 0;
    padding: 0;
}

.mySwiper {
    .swiper-slide {
        // background: linear-gradient(61.53deg, rgba(0, 0, 0, 0.7) 23.77%, rgba(0, 0, 0, 0) 43.36%);
        background: url("/assets/banner/bg.png");
    }

    .title_container {
        &.swiper-slide-title {
            display: flex;
            flex-direction: column;
            height: 100vh;
            justify-content: flex-end;
            align-items: flex-start;
            color: #fff;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            height: 100vh;
            padding-left: 100px;

            h2 {
                font-family: "Anybody";
                font-style: italic;
                font-weight: 700;
                font-size: 48px;
                line-height: 50px;
                color: #fff;
                margin-bottom: 30px;
                max-width: 45%;
            }

            p {
                font-family: "Space Grotesk";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 26px;
                margin: 0;
                max-width: 45%;
                padding-bottom: 50px;
                max-width: 55%;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }
}

.swiper-pagination {
    left: 50px !important;

    .swiper-pagination-bullet {
        margin-bottom: 20px !important;
        height: 15px;
        width: 15px;
        background: url("/assets/icons/dot.png");
        background-size: contain;
        opacity: 1;

        &.swiper-pagination-bullet-active {
            height: 15px;
            width: 15px;
            background: url("/assets/icons/activeDot.png");
            background-size: contain;
        }
    }
}